import { Component } from '@angular/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { CookieService } from '@services/cookie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app-login-brand';
  constructor(
    private cookieServices: CookieService
  ) {
    const isAuthenticated = this.cookieServices.get('auth_tkt');
    if (isAuthenticated !== '') {
      window.location.href = '/sales';

    }

    setTheme('bs4');
  }
}
